<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Accounting" />
		<div>
			<div v-if="!!loading">Loading</div>
			<div v-if="!loading">
				<div>
					<section>
						<b-field label="Select beginning date">
							<b-datepicker
								v-model="selectedBd"
								:show-week-number="showWeekNumber"
								placeholder="Type or select a date..."
								icon="calendar-today"
								:locale="locale"
								:first-day-of-week="firstDayOfWeek"
								trap-focus
							>
							</b-datepicker>
						</b-field>
					</section>
				</div>
				<div>
					<section>
						<b-field label="Select ending date">
							<b-datepicker
								v-model="selectedEd"
								:show-week-number="showWeekNumber"
								placeholder="Type or select a date..."
								icon="calendar-today"
								:locale="locale"
								:first-day-of-week="firstDayOfWeek"
								trap-focus
							>
							</b-datepicker>
						</b-field>
					</section>
				</div>
			</div>
			<div>
				<b-button class="is-success margin-top-20" @click="refresh">
					Refresh
				</b-button>
			</div>
			<div class="margin-top-20">
				<ve-table
					:columns="columns"
					:rows="rows"
					:searchedOptions="true"
					:sort-options="sortOptions"
					:onRowDoubleClick="onRowDoubleClick"
					:onCellClick="onCellClick"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button class="is-small is-success">Select</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</ve-table>
			</div>
			<div v-if="!!isModalVisible">
				<modal-invoice-frame />
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import GetOutLandInvoices from '@/_srcv2/pages/accounting/page-outland-invoices/graphql/GetOutLandInvoices.graphql'
import { ref, reactive, onMounted } from '@vue/composition-api'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import dateformat from 'dateformat'
import ModalInvoiceFrame from '@/_srcv2/pages/_reports/get-data-and-report/invoice/ModalInvoiceFrame'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore'

export default {
	name: 'OutLandInvoices',
	components: {
		SubNavbar,
		ModalInvoiceFrame,
	},
	setup() {
		// --------------------------------------------------------------------------
		const locale = ref('sv-SE')
		const selectedBd = ref(Store.getters.getToday)
		const selectedEd = ref(Store.getters.getToday)
		const showWeekNumber = ref(true)
		const firstDayOfWeek = 1
		// ---------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetOutLandInvoices,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				bd: dateformat(selectedBd.value, 'yyyy-mm-dd'),
				ed: dateformat(selectedEd.value, 'yyyy-mm-dd'),
			}),
			options,
		)
		// ----------------------------------------------------------------------------
		const rows = ref([])
		const columns = ref([])
		const mapData = (array) => {
			return array.map((item) => {
				console.log('array, array')
				return {
					invoiceDate: item.invoice_date,
					invoiceNumber: item.invoice_number,
					invoiceType: item.invoice_type,
					customer: `${item.customer.customer_id} - ${item.customer.customer_title} - ${item.customer.customer_nickname}`,
				}
			})
		}
		const setData = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const tableRows = mapData(result)
					resolve(tableRows)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const refresh = () => {
			refetch()
				.then((result) => setData(result.data.document_transactions))
				.then((tableRows) => (rows.value = tableRows))
				.then(() => console.log('rows.value', rows.value))
		}
		// ----------------------------------------------------------------------
		onMounted(() => {
			selectedBd.value = Store.getters.getToday
			selectedEd.value = Store.getters.getToday
		})
		// --------------------------------------------------------------------------
		columns.value = [
			{
				label: 'Select',
				field: 'select',
				width: '100px',
				sortable: false,
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '100px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '100px',
			},
			{
				label: 'Invoice Type',
				field: 'invoiceType',
				width: '100px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '550px',
			},
		]
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceNumber', type: 'asc' },
		}
		// -----------------------------------------------------------------------------------
		const getPdfReport = (row) => {
			setVariables(row)
				.then(() => (isModalVisible.value = true))
				.then(() =>
					console.log(
						'reportDocumentNumber: ',
						reportDocumentNumber.value,
						'row: ',
						row,
					),
				)
		}
		// ('select' || 'invoiceNumber')
		const onCellClick = (params) => {
			if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'select'
			) {
				getPdfReport(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			getPdfReport(params.row)
		}
		//---------------------------------------------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// -------------------------------------------------------------------------------------------------
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					console.log('*** setVariables row', row)
					reportDocumentType.value = 'sale-invoice'
					reportDocumentNumber.value = row.invoiceNumber
					reportDocumentOurCompany.value = Store.getters.getUserCurrentCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = row.customer
					reportDocumentDate.value = row.invoiceDate
					reportDocumentStatus.value = 'locked'
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ---------------------------------------------------------------------------------------
		return {
			loading,
			refresh,
			locale,
			showWeekNumber,
			selectedBd,
			selectedEd,
			firstDayOfWeek,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			isModalVisible,
		}
	},
}
</script>

<style scoped></style>
